import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import type { LinksFunction, MetaFunction } from "@remix-run/cloudflare";

import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useRouteError,
} from "@remix-run/react";
import RadixProvider from "./lib/rdt/radixProvider";
import Header from "./components/header";
import TransactionNotification from "./components/transaction-notification";

import './styles/app.css';
// import { appsignal } from "./lib/appsignal";

export function links() {
   return [
      // { rel: 'stylesheet', href: styles },
      { rel: 'shortcut icon', type: 'image/svg+xml', href: 'https://hug.meme/hug.png' },
      { rel: 'mask-icon', sizes: 'any', href: 'https://hug.meme/hug.png', color: '#5D0FC0' },
   ];
}

export const meta: MetaFunction = () => {
   return [{ title: 'HUG Meme' }];
};

function App() {
	return <Outlet />;
}

export default withSentry(App);

export function ErrorBoundary() {
   const error = useRouteError();

   // if (error instanceof Error) {
   //    appsignal.sendError(error, (span) => {
   //       span.setAction('CatchAll');
   //    });
   // }

   // when true, this is what used to go to `CatchBoundary`
   if (isRouteErrorResponse(error)) {
      return (
         <html lang="en" className="bg-fixed bg-top bg-cover bg-no-repeat bg-hug">
            <head>
               <Meta />
               <Links />
            </head>
            <body>
               <Header />
               <main className="mt-5 md:mt-12">
                  <div className="md:w-4/5 lg:w-3/4 xl:w-1/2 2xl:w-2/5 mx-4 mb-8 md:mx-auto">
                     <h1 className="text-4xl font-bold text-left p-8 text-white">Oops</h1>
                     <p>Status: {error.status}</p>
                     <p>{error.data.message}</p>
                  </div>
               </main>
            </body>
         </html>
      );
   }

   // Don't forget to typecheck with your own logic.
   // Any value can be thrown, not just errors!
   let errorMessage = 'Unknown error';
   captureRemixErrorBoundaryError(error);
   // if (isDefinitelyAnError(error)) {
   //    errorMessage = error.message;
   // }

   return (
      <html lang="en" className="bg-fixed bg-top bg-cover bg-no-repeat bg-hug">
         <head>
            <Meta />
            <Links />
         </head>
         <body>
            <Header />
            <main className="mt-5 md:mt-12">
               <div className="md:w-4/5 lg:w-3/4 xl:w-1/2 2xl:w-2/5 mx-4 mb-8 md:mx-auto">
                  <h1 className="text-4xl font-bold text-left p-8 text-white">An unexpected error occured.</h1>
                  <p>It's me, hi, I'm the problem, it's me:</p>
                  <pre>{errorMessage}</pre>
               </div>
            </main>
         </body>
      </html>
   );
}